
const AOS = require('aos');


window.onload = () => {
    // -----Animate On Scroll -------//
    AOS.init();

    window.addEventListener('scroll', () => {
        var header = document.querySelector("header")
        header.classList.toggle("scroll", window.scrollY > 80);
    })
}