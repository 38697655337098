import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// import { PiHandshakeDuotone } from "react-icons/pi";



import x_icon_d from './../media/icons/x_icon_d.png'
import tg_icon_d from './../media/icons/tg_icon_d.png'
import ig_icon_d from './../media/icons/ig_icon_d.png'
import check_icon from './../media/icons/check.png'
import st_icon_1 from './../media/icons/st-icon-1.png'
import st_icon_2 from './../media/icons/st-icon-2.png'
import copy_icon from './../media/icons/copy.png'
import dex_icon from './../media/icons/dex-icon.png'

import bb_logo_single from './../media/img/bdg_logo_single.png'
import bb_8 from './../media/img/bdg_bg_8.jpg'

import { ReactComponent as BscLogo } from './../media/img/bscscan-logo.svg';

import bsc_icon from './../media/icons/bsc_icon.png'
import meta_icon from './../media/icons/meta-icon.png'
import set_icon from './../media/icons/set-icon.png'
import x_icon from './../media/icons/x-icon.png'
import twitter_icon from './../media/icons/twitter-icon.jpg'
import tg from './../media/icons/tg-icon.png'


import tg_icon from './../media/icons/tg.png'
import twitter from './../media/icons/twitter.png'
import mail_icon from './../media/icons/mail-icon.png'

const Home = () => {


    const [htwCount, setHtwcount] = useState(0)



    return ( 
        <div className="">

            <section id="home" className="home">
                <div className="main-frame">
                    <div className="flex row align-center justify-center">
                        <div className="home-text flex column align-center">
                            <img src={bb_logo_single} className="home-text-logo-ant" alt="" data-aos="zoom-in" data-aos-duration="2000" />
                            <div className="home-head-subhead" data-aos="fade-right" data-aos-duration="2000">
                                <h2>THE OFFSPRING OF DOGITA</h2>
                                {/* <h3 className="text-align-center">Number request of CMC :897778</h3> */}
                                {/* <h3>Next Generation Gaming Platform That Offers a Whole New Experience in the World of Cryptocurrency.</h3> */}
                            </div>
                            <div className="flex row gap-15 mgb-30" data-aos="fade-left" data-aos-duration="2000">
                                <a href="https://x.com/baby_doga/" target="_blank" className="home-ct-icon-box flex justify-center align-center">
                                    <img src={x_icon} alt="" />
                                </a>
                                <a href="https://t.me/BabyDogitaCoin" target="_blank" className="home-ct-icon-box flex justify-center align-center">
                                    <img src={tg} alt="" />
                                </a>
                                {/* <a href="" className="home-ct-icon-box flex justify-center align-center">
                                    <img src={mail_icon} alt="" />
                                </a> */}
                                <a href="https://poocoin.app/tokens/0x1e599236c459cb13a85ead4fafb24d64ae154f6f" target="_blank" 
                                    className="home-ct-icon-box flex justify-center align-center"
                                >
                                    <img src={dex_icon} alt="" />
                                </a>
                            </div>
                            <div className="flex row justify-center align-center gap-5 copy-address-box" data-aos="fade-up" data-aos-duration="2000">
                                <img src={copy_icon} alt="" />
                                <p>0x1e599236c459cb13a85ead4fafb24d64ae154f6f</p>
                            </div>
                            <div className="flex row align-center gap-15" data-aos="fade-up" data-aos-duration="2000">
                                <a href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x1E599236c459Cb13A85EaD4fAfb24D64aE154F6F"  target="_blank" className='btn-1'>
                                    Buy Now
                                </a>
                                <a href="https://t.me/BabyDogitaCoin" target="_blank" className='btn-sp'>
                                    Join Community
                                </a>
                            </div>
                        </div>
                        {/* <div className="home-img">
                            <img src={logo_vib} alt="" />
                        </div> */}
                    </div>
                </div>
            </section>


            <section className="about" id="about">
                <div className="main-frame">
                    <div className="flex row wrap align-center">
                        <div className="about-text" data-aos="fade-right" data-aos-duration="2000">
                            {/* <h2>About Popeye</h2> */}
                            <h3>Introducing BBDOGA: The Baby Dogita</h3>
                            <p>
                                Introducing BabyDOGITO, the delightful offspring of DOGITO and DOGITA! Born on the Binance 
                                Smart Chain (BSC), BabyDOGITO inherits all the charm and community spirit of its parents. 
                                This lovable memecoin aims to bring joy and rewards to its holders, with exciting new features 
                                and benefits that promise to enhance your crypto experience. Get ready to join the 
                                BabyDOGITO family and be part of a fun and rewarding journey!
                            </p>

                            <a href="https://t.me/BabyDogitaCoin" className="about-btn flex row gap-15 justify-center align-center">
                               <span>Join The Community</span> 
                               <img src={tg_icon} alt="" />
                            </a>
                        </div>
                        <div className="about-img" data-aos="fade-left1" data-aos-duration="2000">
                            {/* <video src={pop_logo_intro} muted autoPlay loop /> */}
                            <img src={bb_8} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tokenomics" id="tokenomics">
                <div className="main-frame">                    
                    <div className="tk-header overflow-y-hidden" data-aos="fade-right" data-aos-duration="2000">
                        <h2>Total Supply <span>10 M</span></h2>
                        <p data-aos="fade-up" data-aos-duration="2000">
                            $BBDOGA is a BEP-20 will empower the community members with voting, and management rights with 0% Tax forever.
                        </p>
                    </div>
                    <div className="grid grid-column-2 row grid-column-gap-40 grid-row-gap-20 tk-grid-box">
                        <div className="tk-item flex justify-center align-center" data-aos="fade-right" data-aos-duration="2000">
                            <div className="tk-item-inner">
                                <h2>Name/Ticker</h2>
                                <h3>$BBDOGA</h3>
                            </div>
                        </div>
                        <div className="tk-item flex justify-center align-center" data-aos="fade-left" data-aos-duration="2000">
                            <div className="tk-item-inner">
                                <h2>Tax</h2>
                                <h3>4%/4%</h3>
                                {/* <h4>1% - LP | 4% - Marketing | 2% - Burn</h4> */}
                            </div>
                        </div>
                        <div className="tk-item flex justify-center align-center" data-aos="fade-right" data-aos-duration="2000">
                            <div className="tk-item-inner">
                                <h2>Total Supply</h2>
                                <h3>10.000.000</h3>
                                {/* <h4>20% of Total supply has been Burnt</h4> */}
                            </div>
                        </div>
                        {/* <div className="tk-item flex justify-center align-center" data-aos="fade-right" data-aos-duration="2000">
                            <div className="tk-item-inner">
                                <h2>Total Supply</h2>
                                <h3>200.000.000.000.000.000</h3>
                                <h4>10% of Total supply has been Burnt</h4>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="roadmap" id="roadmap">
                <div className="main-frame">
                    <div className="roadmap-header overflow-y-hidden" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Learn about our path</h2>
                        <h3>The Roadmap of $BBDOGA</h3>
                    </div>

                    <div className="roadmap-item-cont grid grid-column-3 grid-column-gap-25 grid-row-gap-10 overflow-y-hidden">
                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 1</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Create Smart Contract</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch $BBDOGA Token</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>Launch Website</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"><img src={check_icon} alt="" /></div> <p>BSC Trending Campaign</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 2</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Launch "Search for Baby Dogita" Campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>500-1000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Partnership</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>On-going Prizes</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>More Partnership</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Marketing</p>
                            </div>
                        </div>

                        <div className="rd-item" data-aos="flip-right" data-aos-duration="2000">
                            <h2>Phase 3</h2>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>1500 - 2000 Holders</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Build the strongest Community in Crypto meme space</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Pushing for top 500 Top Crypto Tokens by Marketcap</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Massive meme marketing campaign</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p>Empowering our community</p>
                            </div>
                            <div className="flex row gap-10 rd-list-box">
                                <div className="flex justify-center align-center"></div> <p> Strengthening the BBDOGA ecosystem</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="htb" id="htb">
                <div className="main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Be a Part of the $$BBDOGA Movement</h2>
                        <h3>How to buy $BBDOGA</h3>
                    </div>
                    <div className="grid grid-column-2 row grid-column-gap-40 grid-row-gap-20 overflow-y-hidden">


                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={meta_icon} alt="" />
                                </div>
                                <h4>Create MetaMask Wallet and Connect</h4>
                                <p>
                                    Download Metamask or a wallet of your choice from the following list and create a wallet: <br />
                                    <a href="hhttps://www.coinbase.com/" target="_blank" className="display-inline">Coinbase Wallet</a> – Best for Beginners <br />
                                    <a href="https://metamask.io/" target="_blank" className="display-inline">MetaMask</a> – Best for Desktop <br />
                                    <a href="https://trustwallet.com/" target="_blank" className="display-inline">TrustWallet</a> – Best for Mobile <br />
                                    We recommend you confirm you are on the correct website when clicking any links or doing anything related to cryptocurrency. Do not get scammed!
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={bsc_icon} alt="" />
                                </div>
                                <h4>Buy BNB</h4>
                                <p>
                                    In order to purchase $BBDOGA, you must have BNB. To acquire BNB, you can purchase 
                                    it from several centralized exchanges such
                                     as <a href="https://coinbase.com/">Coinbase</a>, <a href="https://binance.com/">Binance</a> and <a href="https://kucoin.com/">KuCoin</a>. 
                                    You must then send the BNB from the exchange to your wallet.
                                </p>
                            </div>
                        </div>
                        
                        <div className="htb-item" data-aos="flip-right" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={set_icon} className="htb-ray" alt="" />
                                </div>
                                <h4>Connect to PancakeSwap</h4>
                                <p>
                                    Go to Pancakeswap and connect your wallet of choice to the dApp. Paste the $BBDOGA 
                                    token address into the output currency if it is not already loaded.<br /><br />

                                    The contract address for $BBDOGA is Adddresss.
                                    Any contract addresses other than this are fake and may be a scam/honeypot. Be careful.
                                </p>
                            </div>
                        </div>

                        <div className="htb-item" data-aos="flip-left" data-aos-duration="2000">
                            <div className="htb-item-inner">
                                <div className="flex justify-center">
                                    <img src={bb_logo_single} alt="" />
                                </div>
                                <h4>Swap BNB to Baby Dogita</h4>
                                <p>
                                    Now that you have an bnb wallet, you own BNB and you are connected to Pancakeswap, you 
                                    can now swap BNB to BBDOGA. There are no taxes for swaps to and from BBDOGA, 
                                    so you can enjoy your BBDOGA tokens with no hidden fees!
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* <section className="eco" id="eco">
                <div className="wider-frame-percentage">
                    <div className="eco-header" data-aos="fade-up" data-aos-duration="2000">
                        <h2>Ecosystem Tokens</h2>
                    </div>

                    <div className="eco-container">
                        <div className="flex row wrap align-center">
                            <div className="eco-text" data-aos="fade-right" data-aos-duration="2000">
                                <h2>Peki Inu</h2>
                                <p className="eco-pgh">
                                    Peki Inu Token's mission is to harness the unparalleled dedication and creativity of its 
                                    predecessor communities to create a decentralized ecosystem that offers more than just 
                                    a digital currency. It aims to provide real utility. Already 10% burn, and we have an auto burn ca 
                                </p>
                                <div className="flex row justify-center align-center gap-5 copy-address-box">
                                    <img src={copy_icon} alt="" />
                                    <p>0x760539FF254A88B925fd940C8Bc01069cf76069b</p>
                                </div>
                                <div className="flex row gap-15">
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                                        target="_blank" className='btn-1'
                                    >
                                        Buy Peki Inu
                                    </a>
                                    <a href="https://top100token.com/address/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" className='btn-sp'>
                                        View Chart
                                    </a>
                                </div>
                                <div className="eco-line"></div>
                                <p className="eco-list">
                                    <span>Token name</span>: Peki Inu <br />
                                    <span>Token Ticker</span>: PekiInu <br />
                                    <span>Total supply</span>: 200.000.000.000.000.000 <br />
                                    <span>Total Burned</span>: 20% <br />
                                </p>
                            </div>
                            <div className="eco-img" data-aos="fade-left" data-aos-duration="2000">
                                <img src={peki_rocket} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="partners" id="partners">
                <div className="partners-header" data-aos="fade-up" data-aos-duration="2000">
                    <h2>Our Partners</h2>
                </div>

                <div className="mid-frame">
                    <div className="flex row wrap overflow-y-hidden">
                        <a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x760539FF254A88B925fd940C8Bc01069cf76069b" 
                            target="_blank" className="partners-item flex justify-center" data-aos="fade-right" data-aos-duration="2000"
                        >
                            <img src={ps_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0xa93d6925f1e1243f4c06f8e1c678629c8365b8f8?t=1713098809201" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-up" data-aos-duration="2000"
                        >
                            <img src={dt_logo} className="partners-item-img" alt="" />
                        </a>
                        <a href="https://bscscan.com/token/0x760539ff254a88b925fd940c8bc01069cf76069b" target="_blank" 
                            className="partners-item flex justify-center" data-aos="fade-left" data-aos-duration="2000"
                        >
                            <BscLogo className="partners-item-img" />
                        </a>
                    </div>
                </div>
            </section> */}

            <section className="coming-soon" id="coming-soon">
                <div className="cs-main-frame">
                    <div className="htb-header" data-aos="fade-up" data-aos-duration="2000">
                        <h3>Coming Soon</h3>
                    </div>
                    <div className="cs-item-grid grid row grid-column-2 grid-column-gap-20 grid-row-gap-20">
                        <div className="cs-item" data-aos="fade-right" data-aos-duration="2000">
                            <div className="cs-top flex row justify-center align-center gap-10">
                                <img src={bb_logo_single} className="cs-nemo" alt="" />
                                <h4>X</h4>
                                <img src={bsc_icon} className="cs-swap-img" alt="" />
                            </div>
                            <h2>BBDOGA BSC Swap</h2>
                            <p>
                                Exciting news! Our new Swap feature is launching soon. You'll be able to swap tokens effortlessly within our 
                                platform, providing seamless and efficient transactions. Stay connected for more updates as we roll out this 
                                game-changing feature to enhance your trading experience! 🔄💰
                            </p>
                        </div>

                        <div className="cs-item" data-aos="fade-left" data-aos-duration="2000">
                            <div className="cs-top flex row justify-center align-center gap-10">
                                <img src={bb_logo_single} className="cs-nemo" alt="" />
                                <h4>X</h4>
                                <img src={st_icon_2} className="cs-swap-img" alt="" />
                            </div>
                            <h2>Staking</h2>
                            <p>
                                Prepare to unlock new rewards! Our highly anticipated Staking feature is on its way. Soon, you'll be able to stake your tokens and 
                                earn generous returns, enhancing your investment and supporting our ecosystem. Stay tuned for more details and get ready to make the most of your holdings! 🚀💎
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="join" id="join">
                <div className="main-frame">
                    <div className="flex">
                        <div className="flex-100 join-bg-1">
                            <div className="join-bg-2">
                                <div className="">
                                    
                                        <div className="flex row gap-10 j-icons" data-aos="fade-up" data-aos-duration="2000">
                                            <a href="https://t.me/BabyDogitaCoin" target="_blank">
                                                <img src={tg_icon} alt="" />
                                            </a>
                                            <a href="https://x.com/baby_doga/" target="_blank">
                                                <img src={twitter} alt="" />
                                            </a>
                                            {/* <a href="" target="_blank">
                                                <img src={mail_icon} alt="" />
                                            </a> */}
                                        </div>
                                        <div className="join-text ">
                                            <h3 data-aos="fade-right" data-aos-duration="2000">Join our Community</h3>
                                            <p data-aos="fade-left" data-aos-duration="2000">Stay connected to $BBDOGA</p>
                                            <a target="_blank" href="https://t.me/BabyDogitaCoin" className="btn-1">Join Telegram</a>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer" id="footer">
                <div className="main-container">
                    <div className="flex row wrap overflow-y-hidden">
                        <div className="footer-item-1" data-aos="fade-right" data-aos-duration="2000">
                            <a href=""><img src={bb_logo_single} className="logo" alt="" /></a>
                            <p>
                                Managed by Baby Dogita.<br />
                                Join The forefront of the Moon Family
                            </p>
                        </div>
                        <div className="footer-item-2" data-aos="fade-up" data-aos-duration="2000">
                            <h3>
                                Explore
                                <div className="underline"><span></span></div>
                            </h3>
                            <ul>
                                <li><a href="#about">project</a></li>
                                <li><a href="https://poocoin.app/tokens/0x1e599236c459cb13a85ead4fafb24d64ae154f6f" target="_blank">Chart</a></li>
                                <li><a href="#roadmap">Roadmap</a></li>
                                <li><a href="#htb">How to Buy</a></li>
                                <li>
                                    <a href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x1E599236c459Cb13A85EaD4fAfb24D64aE154F6F">Buy Now</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-item-1" data-aos="fade-left" data-aos-duration="2000">
                            <h3>Reach Us
                                <div className="underline"><span></span></div>
                            </h3>
                            
                            <div className="social-icons">
                                <a href="https://t.me/BabyDogitaCoin" target="_blank">
                                    <div className="flex justify-center align-center">
                                        <img src={tg_icon} alt="" />
                                    </div>
                                </a>
                                <a href="https://x.com/baby_doga/" target="_blank">
                                    <div className="flex justify-center align-center">
                                        <img src={twitter} alt="" />
                                    </div>
                                </a>
                                <a href="" target="_blank">
                                    <div className="flex justify-center align-center">
                                        <img src={mail_icon} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <p className="copyright">Baby Dogita copyright 2024 - All Rights Reserved</p>
                </div>
            </section>

        </div>
    );
}
 
export default Home;